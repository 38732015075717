import { useMemo } from 'react';

import { LAYER_INDEXES, VIDEO_FORMATS, type VideoFormat } from '@cofenster/constants';

import type { RenderDescription } from '../../../../api/generated';
import type { TextComposition } from '../../../../api/hooks/renderTemplate/useRenderTemplate';
import type { TemplateFontAsset } from '../../../../api/hooks/templateFontAsset/useTemplateFontAssets';

type RenderDescriptionScene = RenderDescription['scenes'][number];

// preview assets in /assets/video/json-template-*.mp4 are 12s long
const SCENE_DURATION_IN_SECONDS = 12;
const SCENE_FOR_TRANSITION_DURATION_IN_SECONDS = 2;
const FPS = 30;

export const useRenderDescriptionForPreview = (
  textCompositions: TextComposition[],
  format: VideoFormat,
  fontAssets: TemplateFontAsset[],
  customText: Record<string, Record<string, string>>,
  previewTransition: boolean,
  customLogoUrl?: string,
  primaryColor?: string,
  secondaryColor?: string,
  uploadedSceneBackgroundUrl?: string
): RenderDescription | undefined => {
  return useMemo(() => {
    if (!textCompositions.length && !previewTransition) return;
    return {
      id: 'fake',
      template: 'json-template',
      background: 'Black' as const,
      totalDurationInFrames: previewTransition
        ? SCENE_FOR_TRANSITION_DURATION_IN_SECONDS * FPS
        : textCompositions.length * SCENE_DURATION_IN_SECONDS * FPS,
      totalDurationInSeconds: previewTransition
        ? SCENE_FOR_TRANSITION_DURATION_IN_SECONDS * 3
        : textCompositions.length * SCENE_DURATION_IN_SECONDS,
      format: {
        name: format,
        ...VIDEO_FORMATS[format],
        fps: FPS,
      },
      fonts: fontAssets
        .filter((fontAsset) => !!fontAsset.fontUrl)
        .map((fontAsset) => ({
          id: fontAsset.id,
          name: fontAsset.name,
          // biome-ignore lint/style/noNonNullAssertion: filtered above
          srcUrl: fontAsset.fontUrl!,
        })),
      colors: {
        primary: primaryColor ?? '#EA1111',
        secondary: secondaryColor ?? '#0ED863',
      },
      logo: {
        srcUrl: customLogoUrl ?? '/assets/favicon-96x96.png',
      },
      intro: null,
      outro: null,
      backgroundMusic: null,
      subtitles: null,
      scenes: previewTransition
        ? [
            fakeTransitionScene(0, format, uploadedSceneBackgroundUrl),
            fakeTransitionScene(1, format, uploadedSceneBackgroundUrl),
            fakeTransitionScene(2, format, uploadedSceneBackgroundUrl),
          ]
        : textCompositions.map((it, index) =>
            fakeVideoScene(it, index, customText, format, uploadedSceneBackgroundUrl)
          ),
    };
  }, [
    format,
    textCompositions,
    fontAssets,
    customText,
    previewTransition,
    customLogoUrl,
    primaryColor,
    secondaryColor,
    uploadedSceneBackgroundUrl,
  ]);
};

const fakeTransitionScene = (
  index: number,
  format: VideoFormat,
  uploadedSceneBackgroundUrl?: string
): RenderDescriptionScene => {
  return {
    id: `scene-${index}`,
    type: 'video',
    withTransition: true,
    sceneTexts: [],
    sceneAssets: [
      {
        id: `scene-${index}-asset`,
        layerIndex: LAYER_INDEXES.main,
        startTime: 0,
        ...(index === 2
          ? {
              __typename: 'RenderDescriptionSceneColor' as const,
              color: '#666666',
              type: 'color' as const,
            }
          : {
              __typename: 'RenderDescriptionSceneVideo' as const,
              srcUrl: uploadedSceneBackgroundUrl ?? `/assets/video/json-template-${format}.mp4`,
              volume: 1,
              playbackRate: 1,
              height: 354,
              width: 630,
              type: 'video' as const,
              sections: [
                {
                  offsetIn: 0,
                  offsetOut: SCENE_FOR_TRANSITION_DURATION_IN_SECONDS,
                  __typename: 'RenderDescriptionSceneVideoSection' as const,
                },
              ],
            }),
        duration: SCENE_FOR_TRANSITION_DURATION_IN_SECONDS,
      } as const,
    ],
  };
};

const fakeVideoScene = (
  textComposition: TextComposition,
  index: number,
  texts: Record<string, Record<string, string>>,
  format: VideoFormat,
  uploadedSceneBackgroundUrl?: string
): RenderDescriptionScene => {
  return {
    id: `scene-${index}`,
    type: 'video',
    withTransition: false,
    sceneTexts: [
      {
        id: `scene-${index}-text`,
        layerIndex: LAYER_INDEXES.main,
        name: textComposition.aeCompositionName,
        startTime: 0,
        duration: SCENE_DURATION_IN_SECONDS,
        texts: textComposition.textInputs.map((textInput, textIndex) => ({
          id: `video-${index}-text-${textIndex}`,
          name: textInput.aeLayerName ?? `text${textIndex}`,
          text: texts?.[textComposition.id]?.[textInput.id] ?? 'Lorem ipsum',
        })),
      },
    ],
    sceneAssets: [
      {
        id: `scene-${index}-asset`,
        layerIndex: LAYER_INDEXES.main,
        startTime: 0,
        srcUrl: uploadedSceneBackgroundUrl ?? `/assets/video/json-template-${format}.mp4`,
        volume: 1,
        duration: SCENE_DURATION_IN_SECONDS,
        height: 354,
        width: 630,
        type: 'video',
        sections: [
          {
            offsetIn: 0,
            offsetOut: SCENE_DURATION_IN_SECONDS,
            __typename: 'RenderDescriptionSceneVideoSection',
          },
        ],
      },
    ],
  };
};
